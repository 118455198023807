const mode_of_payment = [
    { text: 'All', value: '' },
    { text: 'OFFLINE', value: 'offline' },
    { text: 'ONLINE', value: 'online' },
]

const offline_type_of_payment = [
    { text: '-', value: '' },
    { text: 'CASH DEPOSIT', value: 'cash_deposit' },
    { text: 'CASH-CSC CASHIER', value: 'cash-csc_cashier' },
    { text: 'CHECK DEPOSIT', value: 'check_deposit' },
    { text: 'CHECK-CSC CASHIER', value: 'check-csc_cashier' },
    { text: 'DEBIT MEMO', value: 'debit_memo' },
    { text: 'LDDAP-ADA', value: 'lddap_pada' },
    { text: 'FUND TRANSFER', value: 'fund_transfer' },
]

const online_type_of_payment = [
    { text: '-', value: '' },
    { text: 'LINKBIZ PORTAL', value: 'linkbiz_portal' },
]

const payment_status = [
    { text: 'All', value: '' },
    { text: 'APPROVED', value: 1 },
    { text: 'PENDING', value: 2 },
    { text: 'DISQUALIFIED', value: 3 },
    { text: 'CANCELLED', value: 4 },
]

const enrollment_status = [
    { text: 'All', value: '' },
    { text: 'ENROLLED', value: '1' },
    { text: 'NOT ENROLLED', value: '2' },
]

export  { mode_of_payment, offline_type_of_payment, online_type_of_payment, payment_status, enrollment_status }